
import axios from 'axios';  

import config from '../config';

const { apiHost } = config.oidc;



async function getAuthorizedFactoryFromAirtable (userEmail, accessToken){

    const data = {email:userEmail}

    try {

        let res = await axios.post(`${apiHost}/costingaccessories/get/factoryauthorization`, 
            data,
            { headers: { 
                'Authorization': `'Bearer ${accessToken}'`
            }}
        )

        return res.data // TODO what is bad factory

    } catch (error) {
        console.error (error)
        return false
    }
}

export  {getAuthorizedFactoryFromAirtable}

