import React from 'react';
import {
  Modal
} from 'react-bootstrap';


class SuccessModal extends React.Component {
  constructor(props, context){
    super(props, context);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
        show: false,
        message: "You may continue to make updates",
        title: "Submission Complete"
    }
}

handleShow(title, message) {

    if (title !== undefined) this.setState({title: title}) 
    if (message !== undefined) this.setState({message: message})
    
    this.setState({ show: true })
}

handleClose(){
	this.setState({ show: false })
}

render() {

    return (
       <div>
          <Modal show={this.state.show} onHide={this.handleClose}>
		  		<Modal.Header>
					<Modal.Title>{this.state.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{this.state.message}</Modal.Body>
				<Modal.Footer>
					<button type="button" onClick={this.handleClose}>Close</button>
					{/* <button>Save</button> */}
				</Modal.Footer>
          </Modal>
        </div>
    )
  }
}
export default SuccessModal